import React from "react"
import { withTranslation } from 'react-i18next'
import "./style.css";


class Err extends React.Component {



	render() {

		const {t} = this.props

		return (
			<div className="error">
				<div className="error_content">
					<div className="error_title">{t('error')}</div>
					<div className="error_text">{t('not found')}</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Err)