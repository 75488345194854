import React from "react"
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'

import Header from '../../header'
import Form from './form' 

import "./style.css"

let vacancies_ru = require('./vacancies_ru.json');
let vacancies_en = require('./vacancies_en.json');


class Vacancy extends React.Component {

	state = {
		position: 1,
		form: false
	}

	scrollPage = () => {
		window.scrollTo({
			top: this.myRef.offsetTop - 50,
			behavior: 'smooth'
		})
	}

	clickPosition = content => {

		if(document.body.clientWidth < 768) {
			this.setState({
				position: this.state.position === content ? null : content
			})
		} else {
			this.setState({
				position: content
			})
		}
	}

	appearForm = () => {
		this.setState({
			form: !this.state.form
		})
	}


	render() {

		let { position, form } = this.state
		const {t} = this.props

		let vacancies;

		if(t('status') === 'en') {
			vacancies = vacancies_en
		} else {
			vacancies = vacancies_ru
		}


		return (
			<div>
				<Helmet>
					<title>{t('header.menu_item_3')} - InsyDev</title>
					<meta name="description" content="Актуальные вакансии. Не нашли походящую вакансию?\n
						Присылайте нам свое резюме, и мы свяжемся с вами, как только у нас появится вакансия, которая соответствует вашим интересам и профессиональному уровню!" />
				</Helmet>

				{form && 
					<div className="vacancy_popup">
						<Form>
							<div className="form_close" onClick={this.appearForm}></div>
						</Form>
					</div>	
				}

				<Header/>

				<div className="vacancy">
					<div className="vacancy_banner">
						<div className="app_content">
							<div className="vacancy_banner_content">
								<div className="vacancy_banner_title">{t('vacancy.title')}</div>
								<div className="vacancy_banner_text">
									{t('vacancy.text')}
								</div>
							</div>
							<div className="vacancy_banner_scroll" onClick={this.scrollPage}>
								<div className="vacancy_banner_scroll_block"></div>
								<div className="vacancy_banner_scroll_arrow"></div>
							</div>
						</div>
					</div>

					<div className="vacancy_actual app_content" ref={ref => this.myRef=ref }>
						<div className="vacancy_actual_title">
							{t('vacancy.vacancies.title')} 
							<span className="vacancy_actual_title_number">4</span>
						</div>

						<div className="vacancy_actual_content">
							<div className="vacancy_actual_list">

								{vacancies.map(el => {
									return (
										<div key={el.id} className={position=== el.id ? 'vacancy_actual_list_item_active vacancy_actual_list_item' : 'vacancy_actual_list_item' }
										>
											<div className="vacancy_actual_list_item_content" onClick={() => this.clickPosition(el.id)}>
												<div className='vacancy_actual_list_item_eye'></div>
												<div className="vacancy_actual_list_item_title">{el.title}</div>
											</div>
											
											<div className="vacancy_actual_position">
												<div className="vacancy_actual_position_title">{el.title}</div>

												
												<div className="vacancy_actual_position_block">
													<div className="vacancy_actual_position_block_title">{t('vacancy.vacancies.responsibilities')}</div>
													{el.responsibilities.map((item,idx) => {
														return (
															<div key ={idx} className="vacancy_actual_position_block_item">{item}</div>
														)
													})}
													
												</div>

												<div className="vacancy_actual_position_block">
													<div className="vacancy_actual_position_block_title">{t('vacancy.vacancies.expectation')}</div>
														{el.expectations.map((item,idx) => {
															return (
																<div key={idx} className="vacancy_actual_position_block_item">{item}</div>
															)
														})}
												</div>

												<div className="vacancy_actual_position_block">
													<div className="vacancy_actual_position_panel">
														<div className="vacancy_actual_position_panel_item">
															<div className="vacancy_actual_position_panel_title">{t('vacancy.vacancies.skills')}</div>
															<div className="vacancy_actual_position_panel_content">
																<div className="vacancy_actual_position_panel_icon vacancy_actual_position_panel_icon_skill"></div>
																<div className="vacancy_actual_position_panel_text">{el.skills}</div>
															</div>
														</div>

														<div className="vacancy_actual_position_panel_item">
															<div className="vacancy_actual_position_panel_title">{t('vacancy.vacancies.experience')}</div>
															<div className="vacancy_actual_position_panel_content">
																<div className="vacancy_actual_position_panel_icon vacancy_actual_position_panel_icon_experience"></div>
																<div className="vacancy_actual_position_panel_text">{el.experience}</div>
																
															</div>
														</div>

														<div className="vacancy_actual_position_panel_item">
															<div className="vacancy_actual_position_panel_title">{t('vacancy.vacancies.office')}</div>
															<div className="vacancy_actual_position_panel_content">
																<div className="vacancy_actual_position_panel_icon vacancy_actual_position_panel_icon_location"></div>
																<div className="vacancy_actual_position_panel_text">{el.location}</div>
																
															</div>
														</div>
													</div>
												</div>

												<div className="vacancy_actual_position_response">
													<button className="vacancy_actual_position_response_button" onClick={this.appearForm}>{t('vacancy.vacancies.respond')}</button>
													<div className="vacancy_actual_position_response_panel">
														<div className="vacancy_actual_position_response_text">{t('vacancy.vacancies.respond_via')}</div>
														{/*<a className="vacancy_actual_position_response_linkedin"></a>*/}
														<a href={el.tut} target="true" className="vacancy_actual_position_response_tut"></a>
													</div>
												</div>

											</div>

										</div>
									)
								})}

								
							</div>

							{vacancies
								.filter(el => el.id === position)
								.map(el => {

									return (
										<div key={el.id} className="vacancy_actual_position">
											<div className="vacancy_actual_position_title">{el.title}</div>


											<div className="vacancy_actual_position_block">
												<div className="vacancy_actual_position_block_title">{t('vacancy.vacancies.responsibilities')}</div>
												{el.responsibilities.map((item,idx) => {
													return (
														<div key={idx} className="vacancy_actual_position_block_item">{item}</div>
													)
												})}
											</div>

											<div className="vacancy_actual_position_block">
												<div className="vacancy_actual_position_block_title">{t('vacancy.vacancies.expectation')}</div>
													{el.expectations.map((item,idx) => {
														return (
															<div key={idx} className="vacancy_actual_position_block_item">{item}</div>
														)
													})}
											</div>


											<div className="vacancy_actual_position_block">

												<div className="vacancy_actual_position_panel">
													<div className="vacancy_actual_position_panel_item">
														<div className="vacancy_actual_position_panel_title">{t('vacancy.vacancies.skills')}</div>
														<div className="vacancy_actual_position_panel_content">
															<div className="vacancy_actual_position_panel_icon vacancy_actual_position_panel_icon_skill"></div>
															<div className="vacancy_actual_position_panel_text">{el.skills}</div>
															
														</div>
													</div>

													<div className="vacancy_actual_position_panel_item">
														<div className="vacancy_actual_position_panel_title">{t('vacancy.vacancies.experience')}</div>
														<div className="vacancy_actual_position_panel_content">
															<div className="vacancy_actual_position_panel_icon vacancy_actual_position_panel_icon_experience"></div>
															<div className="vacancy_actual_position_panel_text">{el.experience}</div>
															
														</div>
													</div>

													<div className="vacancy_actual_position_panel_item">
														<div className="vacancy_actual_position_panel_title">{t('vacancy.vacancies.office')}</div>
														<div className="vacancy_actual_position_panel_content">
															<div className="vacancy_actual_position_panel_icon vacancy_actual_position_panel_icon_location"></div>
															<div className="vacancy_actual_position_panel_text">{el.location}</div>
															
														</div>
													</div>
												</div>
											</div>

											<div className="vacancy_actual_position_response">
												<button className="vacancy_actual_position_response_button" onClick={this.appearForm}>{t('vacancy.vacancies.respond')}</button>
												<div className="vacancy_actual_position_response_panel">
													<div className="vacancy_actual_position_response_text">{t('vacancy.vacancies.respond_via')}</div>
													{/*<div className="vacancy_actual_position_response_linkedin"></div>*/}
													{<a href={el.tut} target="true" className="vacancy_actual_position_response_tut"></a>}
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>


					<div className="vacancy_search">
						<div className="app_content">
							<div className="vacancy_search_wrapper">
								<div className="vacancy_search_content">
									<div className="vacancy_search_title">{t('vacancy.title')}</div>
									<div className="vacancy_search_text">{t('vacancy.text')}</div>
								</div>

								<Form />

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Vacancy)









