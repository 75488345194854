import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import resources from "../translations"

var language = window.navigator ? (window.navigator.language ||
                  window.navigator.systemLanguage ||
                  window.navigator.userLanguage) : "ru";

language = language.substr(0, 2).toLowerCase();

i18n
  //      .use(LanguageDetector)
        .use(initReactI18next)
        .use(intervalPlural)
        .init({
                resources,
                fallbackLng: language === "ru" ? "ru" : "en",

                interpolation: {
                        escapeValue: false
                }
        });

export default i18n