import React from "react";
import { withTranslation } from 'react-i18next'

import "./style.css";

class Footer extends React.Component {

	
	render() {
		const {t} = this.props

		return (
			<div className="footer">
				<div className="app_content">
					<div className="footer_logo"></div>

					<div className="footer_panel">
						<div className="footer_company">
							<div className="footer_company_icon"></div>
							<div className="footer_company_text">
								INSYDEV – Intelligent Systems Development, 2019
							</div>
						</div>

						<div className="footer_office">
							<span>{t('footer.office')}</span> {t('footer.address')}
						</div>

						<div className="footer_phone">
							<span> {t('footer.tel')}</span>
							<div className="footer_phone_numbers">
								<a href="tel:+375173880026"> +375 17 388 00 26</a>
								<a href="tel:+375296260938"> +375 29 626 09 38</a>
							</div>
						</div>

						<div className="footer_email">
							<a href="mailto:info@insy.dev">
								<span>Email: </span> info@insy.dev{" "}
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Footer)
