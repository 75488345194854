import React, { Fragment } from "react"
import { Helmet } from 'react-helmet'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";
import { withTranslation } from 'react-i18next'

import Header from '../../header'
import Response from '../../response'

import Postgress from './img/icons/postgress.png'
import Node from './img/icons/node.svg'
import Js from './img/icons/js.png'
import C from './img/icons/c++.png'
import Mongo from './img/icons/mongo.png'
import Css from './img/icons/css.png'
import HTML from './img/icons/html.png'
import Git from './img/icons/git.svg'
import Docker from './img/icons/docker.png'

import "./style.css";


class Main extends React.Component {


	state = {
		direction: null,
		name : '',
		company : '',
		email: '',
		text: '',
		sending: false,
		parcel: null
	}

	clickDirection = content => {
		this.setState({
			direction: content
		})
	}

	scrollPage = () => {
		window.scrollTo({
			top: this.myRef.offsetTop - 50,
			behavior: 'smooth'
		})
	}

	toogleDirection = content => {
		this.setState({
			direction: this.state.direction === content ? null : content
		})
	}

	sendData = e => {
		e.preventDefault();

		let {name, company, email, text} = this.state

		this.setState({
			sending: true
		})

		fetch(`/api/feedback.send?type=feedback&name=${name}&company=${company}&email=${email}&text=${text}`)
			.then(_=>_.json())
			.then(  
		    	async _ => {  

		      		if (_.sent) {
		      			this.setState({
							name : '',
							company : '',
							email: '',
							text: '',
							parcel: 'yes'
		      			})
		      		}
					else {
						this.setState({
							parcel: {
								status: _.error,
								message: _.message
							}
						})
					}
		    	}	  
		  	)  
			.catch(err => {  
		    	console.log('Fetch Error:', err);

		    	this.setState({
		    		parcel:{
		    			status: err.toString(),
		    			message: ''
		    		}
		    	})
			})
			.finally(() => {
				this.setState({
					sending: false
				})
			})
	}

	changeField = (content,e) => {
		this.setState({
			[content]: e.target.value
		})
	}

	closePopup = () => {
		this.setState({
			parcel: null
		})
	}


	render() {

		let { direction, name, company, email, text, sending, parcel } = this.state;

		const {t} = this.props


		return (
			<Fragment>
				<Helmet>
					<title>InsyDev</title>
					<meta name="description" content="Разработка передовых IT решений на базе искусственного интеллекта, компьютерного зрения, интеллектуальных систем управления" />
				</Helmet>

				{parcel && <Response parcel={parcel} closePopup={this.closePopup}/>}

				<Header/>
				<div  className="main">
					<div className="main_banner">
					
						<video src="./insydev-main.mp4"  className="main_banner_video" loop autoPlay  muted playsInline />

						<div className="app_content main_banner_content">
							<div className="main_banner_title">
								Intelligent Systems Development
							</div>
							<div className="main_banner_text">
								{t('main.banner')}
							</div>
						</div>
						<div className="main_banner_scroll" onClick={this.scrollPage}>
							<div className="main_banner_scroll_block"></div>
							<div className="main_banner_scroll_arrow"></div>
						</div>
						
					</div>

					<div className="main_info" ref={ref => this.myRef=ref } >
						<div className="main_direct">
							<div className="app_content">
								<ScrollAnimation animateIn="fadeIn" offset={20} className="main_direct_content">
									<div className="main_direct_title">{t('main.directions.title')}</div>
									<div className="main_direct_text">{t('main.directions.text_1')}
										<div><span className="main_direct_text_active">{t('main.directions.text_2')}</span>{t('main.directions.text_3')}</div>
									</div>
								</ScrollAnimation>
							</div>

								<div className="main_way">

									<div className="main_way_panel">
										<div className="main_way_panel_item main_way_panel_item_vision">
											<div className="main_way_panel_item_content">
												<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_title">
														{t('main.directions.vision.title')}
												</ScrollAnimation>
												
												<div className="main_way_panel_list">
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_1')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_2')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_3')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_4')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_5')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_6')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_7')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.vision.item_8')}</div>
													</ScrollAnimation>
												</div>
											</div>
										</div>
										<div className="main_way_panel_img main_way_panel_img_vision"></div>
									</div>

									<div className="main_way_panel ">
										<div className="main_way_panel_img main_way_panel_img_intelligence"></div>

										<div className="main_way_panel_item main_way_panel_item_intelligence">
											<div className="main_way_panel_item_content">
												<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_title">{t('main.directions.intelligence.title')}</ScrollAnimation>
												<div className="main_way_panel_list">
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.intelligence.item_1')}</div>
													</ScrollAnimation>
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.intelligence.item_2')}</div>
													</ScrollAnimation>
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.intelligence.item_3')}</div>
													</ScrollAnimation>
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.intelligence.item_4')}</div>
													</ScrollAnimation>
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.intelligence.item_5')}</div>
													</ScrollAnimation>
												</div>
											</div>
										</div>
									</div>
								
									<div className="main_way_panel">
										<div className="main_way_panel_item main_way_panel_item_safety">
											<div className="main_way_panel_item_content">
												<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_title">{t('main.directions.safety.title')}</ScrollAnimation>
												<div className="main_way_panel_list">
													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.safety.item_1')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.safety.item_2')}</div>
													</ScrollAnimation>

													<ScrollAnimation offset={20} animateIn="fadeIn" className="main_way_panel_el">
														<div className="main_way_panel_el_icon"></div>
														<div className="main_way_panel_el_text">{t('main.directions.safety.item_3')}</div>
													</ScrollAnimation>
												</div>
											</div>
										</div>
										<div className="main_way_panel_img main_way_panel_img_safety"></div>
									</div>

{/*								<div className="main_direct_block_wrapper">

									<div className="main_direct_video_wrapper">
										<video src="./insydev.mp4"  className="main_direct_video" loop autoPlay  muted playsInline />
									</div>
									

									<div className="main_direct_block">
										<div className="main_direct_block_content">
											<div className="main_direct_block_title">НАПРАВЛЕНИЯ</div>

											<div className="main_direct_block_list">

												<div className={direction === 'vision' ? 'main_direct_block_item_active main_direct_block_item' : 'main_direct_block_item'}>
													
													<div className="main_direct_block_item_content">
														<div className="main_direct_block_item_title_wrapper"
															onClick={() => this.toogleDirection('vision')}
														>
															<div className="main_direct_block_item_icon_eye"></div>
															<div className="main_direct_block_item_title">Компьютерное зрение</div>
															<div className="main_direct_block_item_icon"></div>
															
														</div>
														<div className="main_direct_block_item_list">
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Обнаружение, распознавание, классификация и принятие решения</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Определение, параметров объектов</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Дефектоскопия</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Выделение на изображениях структур определенного вида, сегментация изображений</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Оценка параметров движения</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Событийная видеоаналитика</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Тепловые карты</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Трекинг объектов</div>
															</div>
														</div>
													</div>
													
												</div>

												<div className={direction === 'intelligence' ? 'main_direct_block_item_active main_direct_block_item' : 'main_direct_block_item'}>
													
													<div className="main_direct_block_item_content">
														<div className="main_direct_block_item_title_wrapper" 
															 onClick={() => this.toogleDirection('intelligence')}
														>
															<div className="main_direct_block_item_icon_eye"></div>
															<div className="main_direct_block_item_title">Искусственный интеллект</div>
															<div className="main_direct_block_item_icon"></div>
														</div>

														<div className="main_direct_block_item_list">

															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Экспертные системы</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Машинное обучение</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Извлечение информации</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Планирование</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">BigData (большие данные)</div>
															</div>
														</div>
													</div>
													
												</div>

												<div className={direction === 'safety' ? 'main_direct_block_item_active main_direct_block_item' : 'main_direct_block_item'}>

													<div className="main_direct_block_item_content">
														<div className="main_direct_block_item_title_wrapper"
															onClick={() => this.toogleDirection('safety')}
														>
															<div className="main_direct_block_item_icon_eye"></div>
															<div className="main_direct_block_item_title">Обеспечение безопасности с использованием ИИ</div>
															<div className="main_direct_block_item_icon"></div>
														</div>

														<div className="main_direct_block_item_list">
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Система интеллектуального управления доступом</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Интеллектуальные охранные системы</div>
															</div>
															<div className="main_direct_panel">
																<div className="main_direct_panel_icon"></div>
																<div className="main_direct_panel_text">Системы помощи водителю ADAS (Advanced driver-assistance systems) и автономный транспорт</div>
															</div>
														</div>
													</div>
													
												</div>
											</div>

										</div>
									</div>

								</div>*/}

							</div>
							
						</div>

						<div className="main_technology">
							<div className="app_content main_technology_wrapper">
								<div className="main_technology_title">{t('main.technologies.title')}</div>
								<div className="main_technology_content">{t('main.technologies.text')}</div>
								<div className="main_technology_images">
									<img src={Postgress} alt="img"/>
				 					<img src={Node} alt="img"/>
									<img src={Js} alt="img"/>
									<img src={C} alt="img"/>
									<img src={Mongo} alt="img"/>
									<img src={Css} alt="img"/>
									<img src={HTML} alt="img"/>
									<img src={Git} alt="img"/>
									<img src={Docker} alt="img"/>
								</div>
							</div>
							<div className="main_direct_video_wrapper">
								<video src="./insydev.mp4"  className="main_direct_video" loop autoPlay  muted playsInline />
							</div>
							
						</div>
					</div>

					<div className="app_content main_connection">
						<div className="main_connection_content">
							<div className="main_connection_content_title">{t('main.connection.title')}</div>
							<div className="main_connection_content_text">{t('main.connection.text')}</div>
							<div className="main_connection_content_steps">
								<div className="main_connection_content_step">
									<div className="main_connection_content_step_number">1</div>
									<div className="main_connection_content_step_text">
										{t('main.connection.step_1')}
									</div>
								</div>

								<div className="main_connection_content_step">
									<div className="main_connection_content_step_number">2</div>
									<div className="main_connection_content_step_text">{t('main.connection.step_2')}
										 <a href="mailto:info@insy.dev" className="main_connection_content_step_active"> info@insy.dev</a>
									</div>
								</div>

								<div className="main_connection_content_step">
									<div className="main_connection_content_step_number">3</div>
									<div className="main_connection_content_step_text">{t('main.connection.step_3')}<a href='tel:+375(17)3880026' className="main_connection_content_step_active"> +375 (17) 388 00 26</a>
									</div>
								</div>
							</div>
						</div>

						<form className="main_connection_form" onSubmit={this.sendData}>
							<input type="hidden" name="type" value="feedback"/>

							<div className="main_connection_form_block">
								<div className="main_connection_form_block_title">{t('main.connection.form.name')}<span className="main_connection_form_block_star">*</span></div>
								<input type="text" 
									   placeholder={t('main.connection.form.name_input')} 
									   className="main_connection_form_block_input"
									   value={name}v
									   required
									   onChange={e => this.changeField('name', e)}
									   minLength="2"
									   maxLength="30"
								/>
							</div>

							<div className="main_connection_form_block">
								<div className="main_connection_form_block_title">{t('main.connection.form.company')}</div>
								<input type="text" 
									   placeholder={t('main.connection.form.company_input')} 
									   required
									   className="main_connection_form_block_input"
									   value={company}
									   onChange={e => this.changeField('company', e)}
									   maxLength="30"
								/>
							</div>

							<div className="main_connection_form_block">
								<div className="main_connection_form_block_title">Email<span className="main_connection_form_block_star">*</span></div>
								<input type="email" 
									   placeholder={t('main.connection.form.email_input')}
									   className="main_connection_form_block_input"
									   value={email}
									   onChange={e => this.changeField('email', e)}
								/>
							</div>



							<div className="main_connection_form_block">
								<div className="main_connection_form_block_title">{t('main.connection.form.text')}<span className="main_connection_form_block_star">*</span></div>
					
								<textarea className="main_connection_form_block_textarea" 
										  placeholder={t('main.connection.form.text_input')}
										  required
										  minLength="2"
									   	  maxLength="500"
										  value={text}
									      onChange={e => this.changeField('text', e)}
									      
								></textarea>
							</div>

							<button type="submit" disabled={sending} className="main_connection_form_button">
								{t('main.connection.form.send')}
							</button>
						</form>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withTranslation()(Main)



