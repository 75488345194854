import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import i18n from '../../lib/i18n';

import Logo from './img/logo_light.svg'
import LogoWhite from './img/logo_dark.svg'
import Arrow from './img/arrow.svg'
import ArrowWhite from './img/arrow_white.svg'

import Menu from './img/menu.svg'
import MenuWhite from './img/menu_white.svg'

import Close from './img/close.svg'
import CloseWhite from './img/close.svg'

import IconRu from './img/rus.svg'
import IconEn from './img/en.svg'

import "./style.css"

class Header extends React.Component {

	state = {
		mini: false,
		scroll: false
	}

	toggleMenu = () => {
		this.setState({
			mini: !this.state.mini
		}, () => {
			const {mini} = this.state
			document.body.className = mini ? "body_fixed" : ''
		})
	}


	clickMenu = () => {

		this.setState({
			mini: false
		})

		document.body.className = ''
		
	}

	toggleLang = mean => {
			if(mean === "ru") {
				i18n.changeLanguage('en')
			} else {
				i18n.changeLanguage('ru')
			}
	}

	componentDidMount() {

		if(window.pageYOffset > 0) {
			this.setState({
				scroll: true
			})
		}

		window.addEventListener('scroll', () => {

			if(window.pageYOffset > 0) {
				if(this.state.scroll)
					return

				this.setState({
					scroll: true
				})
			} else {
				this.setState({
					scroll: false
				})
			}
		})

		
	}

	render() {

		let {theme, t} = this.props
		let {mini, scroll} = this.state

		let color = theme === 'white' ? 'rgba(54, 54, 54)' : 'rgba(255, 255, 255)'

		return (
			<div className={
				classNames('header', {
					header_fixed: scroll && !theme,
					header_fixed_white_begin: !scroll && theme==='white',
					header_fixed_white: scroll && theme==='white',
				})
			} style={{boxShadow: theme==='white' ? ' 0px 2px 4px rgba(0, 0, 0, 0.15)' : ''}}>

				<div className="app_content header_content">

					<NavLink to="/" className="header_logo">
						<img src={theme === "white" ? LogoWhite : Logo} alt="ИнсиДэв лого"/>
					</NavLink>
					
						<div className="header_menu">

							<NavLink
								to="/"
								style={{color: color}}
								className="header_menu_item"
								activeClassName="header_menu_item_active"
								exact
							>
								{t('header.menu_item_1')}
							</NavLink>

							<NavLink
								to="/about"
								style={{color: color}}
								className="header_menu_item"
								activeClassName="header_menu_item_active"
							>
								{t('header.menu_item_2')}
							</NavLink>

							<NavLink
								to="/vacancies"
								style={{color: color}}
								className="header_menu_item"
								activeClassName="header_menu_item_active"
							>
								{t('header.menu_item_3')}
							</NavLink>

						</div>
						
						<div className="header_lang" onClick={() => this.toggleLang(t('status'))}>
							<div className="header_lang_flag">
								<img src={t('status') === 'ru' ? IconEn : IconRu} />
							</div>

							<div className="header_lang_text"
								style={{color: theme==="white" ? 'rgba(54, 54, 54, 0.8)' : 'rgba(255, 255, 255, 0.8)'}}
							>{t('status') === 'ru' ? "en" : "рус"}</div>

							<div className="header_lang_icon">
								<img src={theme === "white" ? ArrowWhite : Arrow} alt="img"/>
							</div>

						</div>

						<div className={mini ? 'header_menu_icon_active header_menu_icon' : 'header_menu_icon'} onClick={this.toggleMenu}>
							{mini ? <img src={theme === "white" ? CloseWhite : Close} alt="Меню"/> 
								  : <img src={theme === "white" ? MenuWhite : Menu} alt="Меню"/>
							}
						</div>
					
				</div>

				<div className={mini ? 'header_menu_mini header_menu_mini_active' : 'header_menu_mini'}>
					<NavLink to="/" onClick = {this.clickMenu} className="header_menu_mini_logo"></NavLink>
					<div className="header_menu_mini_items">
						<NavLink to="/" 
								 exact 
								 className="header_menu_mini_item" 
								 activeClassName="header_menu_mini_item_active"
								 onClick = {this.clickMenu}
						>
							{t('header.menu_item_1')}
						</NavLink>

						<NavLink to="/about"  onClick = {this.clickMenu} className="header_menu_mini_item" activeClassName="header_menu_mini_item_active">{t('header.menu_item_2')}</NavLink>
						<NavLink to="/vacancies"  onClick = {this.clickMenu} className="header_menu_mini_item" activeClassName="header_menu_mini_item_active">{t('header.menu_item_3')}</NavLink>
					</div>
					<div className="header_menu_mini_langs">
						<div className="header_menu_mini_lang" onClick={() => this.toggleLang('en')}>
							<div className="header_menu_mini_lang_ru"></div>
							<div className="header_menu_mini_lang_text" style={{textDecoration:t('status') === 'ru' ? "underline" : '', opacity: t('status') === 'ru' ? 0.8 : 0.6}}>рус</div>
						</div>
						<div className="header_menu_mini_lang" onClick={() => this.toggleLang('ru')}>
							<div className="header_menu_mini_lang_en"></div>
							<div className="header_menu_mini_lang_text" style={{textDecoration: t('status') === 'ru' ? "" : 'underline', opacity: t('status') === 'en' ? 0.8 : 0.6}}>en</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default withTranslation()(Header)
