import React, { Fragment } from "react";

import Response from "../../../response";
import { withTranslation } from 'react-i18next'

import "./style.css";

class Form extends React.Component {
	state = {
		name: "",
		email: "",
		phone: "",
		linkedin: "",
		text: "",
		file: "",
		sending: false,
		parcel: null
	};

	changeField = (content, e) => {
		this.setState({
			[content]: e.target.value
		});
	};

	sendData = async e => {
		e.preventDefault();

		let { name, email, phone, linkedin, text, file } = this.state;

		this.setState({
			sending: true
		});

		let obj = new FormData(e.target);

		obj.append("file", file);
		obj.append("name", name);
		obj.append("email", email);
		obj.append("phone", phone);
		obj.append("text", text);

		await fetch(`/api/cv.send`, {
			method: "POST",
			body: obj
		})
			.then(_ => _.json())
			.then(async _ => {
				if (_.sent) {
					this.setState({
						name: "",
						email: "",
						phone: "",
						linkedin: "",
						text: "",
						file: "",
						parcel: "yes"
					});
				} else {
					this.setState({
						parcel: {
							status: _.error,
							message: _.message
						}
					});
				}
			})
			.catch(err => {
				console.log("Fetch Error:", err);

				this.setState({
					parcel: {
						status: err.toString(),
						message: ""
					}
				});
			})
			.finally(() => {
				this.setState({
					sending: false
				});
			});
	};

	attachFile = e => {
		let file = e.target.files[0];

		console.log("file", file);

		this.setState({
			file: file
		});
	};

	closePopup = () => {
		this.setState({
			parcel: null
		});
	};

	render() {
		const {
			name,
			email,
			phone,
			linkedin,
			text,
			file,
			sending,
			parcel
		} = this.state;

		const {t} = this.props

		return (
			<Fragment>
				{parcel && <Response parcel={parcel} closePopup={this.closePopup} />}

				<form className="form" onSubmit={this.sendData}>
					{this.props.children}
					<div className="form_item">
						<div className="form_item_title">
							{t('vacancy.form.name')}<span style={{ color: "red" }}>*</span>
						</div>
						<input
							type="text"
							className="form_item_input"
							placeholder={t('vacancy.form.name_input')}
							value={name}
							required
							onChange={e => this.changeField("name", e)}
							minLength="2"
							maxLength="30"
						/>
					</div>

					<div className="form_block">
						<div className="form_item form_item_email">
							<div className="form_item_title">
								Email<span style={{ color: "red" }}>*</span>
							</div>
							<input
								type="email"
								className="form_item_input"
								placeholder={t('vacancy.form.email_input')}
								required
								value={email}
								onChange={e => this.changeField("email", e)}
							/>
						</div>

						<div className="form_item">
							<div className="form_item_title">{t('vacancy.form.phone')}</div>
							<input
								type="text"
								className="form_item_input"
								placeholder="+___ (__) ___ – __ – __"
								value={phone}
								onChange={e => this.changeField("phone", e)}
								minLength="5"
								maxLength="20"
							/>
						</div>
					</div>

					<div className="form_item">
						<div className="form_item_title">
							{t('vacancy.form.social')}
						</div>
						<input
							type="text"
							className="form_item_input"
							placeholder={t('vacancy.form.social_input')}
							value={linkedin}
							onChange={e => this.changeField("linkedin", e)}
							maxLength="40"
						/>
					</div>

					<div className="form_item">
						<div className="form_item_title">{t('vacancy.form.letter')}</div>
						<textarea
							className="form_item_textarea"
							placeholder={t('vacancy.form.letter_input')}
							value={text}
							maxLength="500"
							onChange={e => this.changeField("text", e)}
						/>
					</div>

					<label className="form_file">
						<input
							type="file"
							ref={ref => (this.file = ref)}
							required
							className="form_file_input"
							accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
							onChange={this.attachFile}
						/>
						{/*<input type="file" id="file" name="file" multiple />*/}
						<div className="form_file_icon"></div>
						<div className="form_file_text">
							{t('vacancy.form.cv')}<span style={{ color: "red" }}>*</span>
						</div>
						{file && <div className="form_file_add">{file.name}</div>}
					</label>

					<button type="submit" disabled={sending} className="form_button">
						{t('vacancy.form.sent')}
					</button>
				</form>
			</Fragment>
		);
	}
}

export default withTranslation()(Form) 













