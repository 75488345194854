import React, { Fragment } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next'

import Header from "../../header";

import "./style.css";

class Company extends React.Component {
	componentDidMount() {}

	render() {
		const {t} = this.props

		return (
			<Fragment>
				<Helmet>
					<title>{t('header.menu_item_2')} - InsyDev</title>
					<meta name="description" content="Контакты" />
				</Helmet>
				<Header theme="white" />
				<div className="company">
					<div className="company_banner">
						<div className="company_banner_img"></div>
						<div className="company_banner_content">
							<div className="company_banner_title_main">
								INSYDEV - Intelligent Systems Development
							</div>
							<div className="company_banner_text">
								{t('about.text')}
							</div>
						</div>
					</div>

					<div className="company_contacts">
						<div className="company_contacts_content">
							<div className="company_contacts_title">{t('about.contacts')}</div>

							<div className="company_contacts_block">
								<div className="company_contacts_item">
									<span className="company_contacts_detailed">{t('about.tel')}</span>
									<a href="tel:+375173880026"> +375 17 388 00 26</a>,{" "}
									<a href="tel:+375296260938">+375 (29) 626 09 38</a>
								</div>
								<div className="company_contacts_item">
									<span className="company_contacts_detailed">Email: </span>
									<a href="mailto:info@insy.dev">info@insy.dev</a>
								</div>
							</div>

							<div className="company_contacts_block">
								<div className="company_contacts_item">
									<span className="company_contacts_detailed">{t('about.office')} </span>
									{t('about.office_text')}
								</div>
							</div>

							<div className="company_contacts_block">
								<div className="company_contacts_item">
									<span className="company_contacts_detailed">{t('about.address')} </span>
									{t('about.address_text')}
								</div>
							</div>
						</div>

						<div className="company_contacts_img" ref={ref => (this.map = ref)}>
							<YMaps>
								{/*<div>My awesome application with maps!</div>*/}
								<Map
									defaultState={{ center: [53.932574, 27.541296], zoom: 16 }}
									width="100%"
									height="100%"
								>
									<Placemark geometry={[53.932574, 27.541296]} />
								</Map>
							</YMaps>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withTranslation()(Company)



