import React from 'react'
import { withTranslation } from 'react-i18next'
import './style.css'

class Response extends React.Component {

	render() {
		const {parcel, t} = this.props;

		return (
			<div className="response">
				<div className="response_popup">
					<div className="response_content">
						<div className={`response_icon_${parcel ==='yes' ? 'yes' : 'error'}`}></div>

						{parcel === 'yes' ? <div className="response_text">{t('thank')}<br/> {t('request')}</div>
							:
						<div className="response_text">{parcel.status}</div>}

						{parcel === 'yes' ? <div className="response_comment">{t('answer')}</div>
							:
						<div className="response_comment">{parcel.message}</div>}

						<button className="response_button" onClick={this.props.closePopup}>ок</button>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Response)