import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import { YMInitializer } from 'react-yandex-metrika';
import  ym from 'react-yandex-metrika';

import {createBrowserHistory} from 'history'

import { Main, Company, Vacancy, Err } from "../comps/pages";
import Footer from "../comps/footer";
import i18n from '../lib/i18n'

import ScrollToTop from '../scroll'

import "./style.css";

let history = typeof document !== 'undefined' ? createBrowserHistory() : undefined;

// let history = createBrowserHistory()
window._ym_debug=1

class App extends React.Component {

	state = {
		prevRef: history && history.location.pathname
	}


	componentDidMount() {

		history && history.listen((location, b) => {
			setTimeout(() => {
				ym('hit', location.pathname, {
					referer: this.state.prevRef,
					title: document.title
				});

				this.setState({
					prevRef: location.pathname
				})
			})
		})
	}


	render() {


		return (
			<ScrollToTop>
				{history ? <Router history={history}>
					<Content />
				</Router> : <Content />}
			</ScrollToTop>
		)
	}
}

const Content = () => (
	<div className="app">
		<YMInitializer accounts={[987654321]}  options={{
            clickmap:true,
            trackLinks:true,
            trackHash: true,
            accurateTrackBounce:true,
            webvisor:true,
            ecommerce:"dataLayer"
        }} version="2" />
	
		<Switch>
			<Route path="/" component={Main} exact></Route>
			<Route path="/about" component={Company}></Route>
			<Route path="/vacancies" component={Vacancy}></Route>
			<Route render={({staticContext}) => {
				if(staticContext) staticContext.status = 404;
				return <Err/>;
			}}/>
		</Switch>
		<Footer />
	</div>
)

export default App;
