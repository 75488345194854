let langs = {
	en: "English",
	ru: "Русский"
}

export { langs }

export default {
	en: require('./en.json'),
	ru: require('./ru.json')
}